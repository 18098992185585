<template>
  <ModuleForm
    :module-name="$t('moduleForm.quiz')"
    :module="quiz"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        :disabled="loading"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="quiz.id"
        :record-id="quiz.id"
        :required-permissions="requiredPermissions.deleteButton"
        :disabled="loading"
        store-name="quizVlm"
        list-router-path="quiz"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/quiz')"
      />
    </template>
    <template #form-content>
      <Preloader v-if="loading" on-top />
      <div class="form-top-part">
        <div class="form-top-part__left">
          <Select
            v-model="quiz.site"
            @blur="$v.quiz.site.$touch()"
            :error="$v.quiz.site.$error"
            :options="sites"
            id="quiz_sites"
            :label="$t('quizVlm.server')"
            :disabled="disabled"
            required
          />
          <Input
            v-model="quiz.title"
            @blur="$v.quiz.title.$touch()"
            :error="$v.quiz.title.$error"
            id="quiz_title"
            :label="$t('quizVlm.title')"
            :disabled="disabled"
            required
          />
          <div class="title-text">
            {{ $t('quizVlm.description') }}
          </div>
          <RichTextEditor
            v-model="quiz.description"
            :other-options="richTextEditorConfig"
            id="bodyText"
            :readonly="disabled"
          />
          <Select
            v-model="quiz.type"
            :options="quizTypeValuesVlm"
            id="type"
            :label="$t('quizVlm.type')"
            no-empty-value
            :disabled="disabled"
            @change="quizTypeChanged"
            class="quiz-type"
          />
          <div class="two-columns">
            <SwitchCheckbox
              v-model="quiz.displayCorrectAnswersImmediately"
              id="quiz_displayCorrectAnswersImmediately"
              :label="$t('quizVlm.displayCorrectAnswersImmediatelyLabel')"
              :false-label="$t('quizVlm.displayCorrectAnswersImmediatelyLabelFalse')"
              :true-label="$t('quizVlm.displayCorrectAnswersImmediatelyLabelTrue')"
              :disabled="disabled"
              class="two-columns__column"
            />
            <SwitchCheckbox
              v-model="quiz.loggedInUsersOnly"
              id="quiz_loggedInUsersOnly"
              :label="$t('quizVlm.loggedInUsersOnlyLabel')"
              :false-label="$t('quizVlm.loggedInUsersOnlyLabelFalse')"
              :true-label="$t('quizVlm.loggedInUsersOnlyLabelTrue')"
              :disabled="disabled"
              class="two-columns__column"
            />
          </div>
        </div>
        <div class="form-top-part__right">
          <div class="title-text">
            {{ $t('quizVlm.image') }}
          </div>
          <ModuleFormPhotoBox
            :image="quiz.imageInfo"
            :disabled="disabled"
            :site="quiz.site"
            :media-usage-type="mediaUsageType"
            @set-media="setImage"
            @remove-media="removeImage"
          />
          <OutlinedCheckbox
            v-model="quiz.enabled"
            id="quiz_enabled"
            :label="$t('quizVlm.enabled')"
            :disabled="disabled"
          />
        </div>
      </div>
      <!-- hide results and questions until initial loading is done, but do not hide them on saving -->
      <QuizFormResultsVlm
        ref="results"
        v-if="!loading || saving"
        :quiz="quiz"
        :disabled="disabled"
      />
      <div class="separator" />
      <QuizFormQuestionsVlm
        ref="questions"
        v-if="!loading || saving"
        :quiz="quiz"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import QuizModel from '@/model/QuizModelVlm'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import NotifyService from '@/services/NotifyService'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import SwitchCheckbox from '@/components/form/SwitchCheckbox'
import { MEDIA_USAGE_TYPE_QUIZ } from '@/model/ValueObject/MediaUsageTypes'
import QuizTypeMixin, { QUIZ_TYPE_CORRECT_ANSWER } from '@/components/mixins/valueObject/QuizTypeMixin'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import RichTextEditor from '@/components/form/RichTextEditor'
import QuizFormResultsVlm from '@/components/quiz/vlm/QuizFormResultsVlm'
import QuizFormQuestionsVlm from '@/components/quiz/vlm/QuizFormQuestionsVlm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import PermissionService from '@/services/PermissionService'
import Preloader from '@/components/preloader/Preloader'

export default {
  name: 'QuizNewViewVlm',
  mixins: [QuizTypeMixin],
  data () {
    return {
      loading: false,
      saving: false,
      quiz: this._.cloneDeep(QuizModel),
      mediaUsageType: MEDIA_USAGE_TYPE_QUIZ,
      richTextEditorConfig: {
        ...TinyMceConfig.getConfig(),
        height: 200,
        autoresize_min_height: 200
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.QUIZ_PERMISSIONS
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/all'].filter(site => site.enabled || site.id === this.quiz.site)
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    quiz: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      site: {
        required
      }
    }
  },
  components: {
    OutlinedCheckbox,
    QuizFormQuestionsVlm,
    QuizFormResultsVlm,
    SwitchCheckbox,
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    Input,
    Select,
    RichTextEditor,
    Preloader
  },
  methods: {
    getQuiz () {
      this.loading = true
      this.$store.dispatch('quizVlm/fetchOne', this.$route.params.id)
        .then(async () => {
          this.quiz = this.$store.getters['quizVlm/detail']
          this.quiz.results && this.quiz.results.sort((a, b) => a.position - b.position)
          if (this.quiz.questions) {
            this.quiz.questions.sort((a, b) => a.position - b.position)
            for (const question of this.quiz.questions) {
              question.answers && question.answers.sort((a, b) => a.position - b.position)
            }
          }
          this.loading = false
        })
    },
    quizTypeChanged () {
      for (const question of this.quiz.questions) {
        for (const answer of question.answers) {
          answer.points = 0
        }
        if (this.quiz.type === QUIZ_TYPE_CORRECT_ANSWER && question.answers.length > 0) {
          question.answers[0].points = 1 // true
        }
      }
    },
    prepareQuizRequest (quiz) {
      this.quiz.results.forEach((result, index) => {
        result.position = index
      })
      this.quiz.questions.forEach((question, index) => {
        question.quiz = this.quiz.id
        question.position = index
        question.answers.forEach((answer, index) => {
          answer.position = index
          if (answer.imageInfo) {
            answer.image = answer.imageInfo.id
          }
        })
      })
      return quiz
    },
    isFormInvalid () {
      const formRefs = [this, this.$refs.results, this.$refs.questions]
      if (this.$refs.questions.$refs.answers) {
        formRefs.push(...this.$refs.questions.$refs.answers)
      }
      let invalid = false
      formRefs.forEach((ref) => {
        if (ref?.$v) {
          ref.$v.$touch()
          invalid = invalid || ref.$v.$invalid
        }
      })
      return invalid
    },
    async save () {
      const formInvalid = this.isFormInvalid()
      const hasQuestions = this.quiz.questions?.length > 0
      if (formInvalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      }
      if (!hasQuestions) {
        NotifyService.setErrorMessage(this.$t('quizVlm.questionsEmptyError'))
      }
      if (formInvalid || !hasQuestions) {
        return
      }
      this.loading = true
      this.saving = true
      const action = this.quiz.id ? 'update' : 'create'
      const successNotify = this.quiz.id ? 'record_was_updated' : 'record_was_created'
      this.$store.dispatch(`quizVlm/${action}`, this.prepareQuizRequest(this.quiz))
        .then(async () => {
          const error = this.$store.getters['quizVlm/error']
          if (!error) {
            this.quiz.id = this.$store.getters['quizVlm/detail'].id
            NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
            this.goToEdit()
          } else {
            console.error(error)
            NotifyService.setErrorMessage(error)
          }
        })
        .catch(error => console.error(error))
        .finally(() => {
          this.loading = false
          this.saving = false
        })
    },
    setImage (medias) {
      this.quiz.imageInfo = medias[0]
      this.quiz.image = medias[0].id
    },
    removeImage () {
      this.quiz.imageInfo = null
      this.quiz.image = null
    },
    goToEdit () {
      this.$router.push(`/quiz/${this.quiz.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.form-top-part {
  display: flex;
  gap: 2%;
  &__left {
    flex: 0 0 65%;
  }
  &__right {
    flex: 0 0 32%;
  }
}
.two-columns {
  display: flex;
  gap: 2%;
  &__column {
    flex: 0 0 49%;
  }
  &__vote-count {
    flex: 0 0 29%;
    margin-top: 2rem;
  }
  &__media-buttons {
    flex: 0 0 69%;
    display: flex;
    justify-content: flex-end;
    gap: 1%;
    margin-top: auto;
  }
}
.four-columns {
  display: flex;
  gap: 1%;
  &__drag-drop {
    flex: 0 0 4%;
    cursor: pointer;
    i {
      display: block;
    }
  }
  &__inputs {
    flex: 0 0 68%;
    margin-top: 1rem;
  }
  &__photo-box {
    flex: 0 0 20%;
  }
  &__delete-button {
    flex: 0 0 4%;
  }
}
.align-middle-center {
  margin: auto;
  text-align: center;
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
  margin-bottom: rem(5px);
}
.separator {
  margin-top: rem(30px);
  margin-bottom: rem(30px);
  background: #D1DBE4;
  height: rem(1px);
}
.quiz-type {
  margin-top: 1rem;
}
.options {
  background-color: #efefef;
  margin-bottom: rem(10px);
  padding: 0.5rem;
  &__answer {
    flex: 0 0 calc(100% - 45px);
    margin-right: rem(10px);
  }
  &__delete-button {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
}
</style>
