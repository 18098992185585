<template>
  <section>
    <div class="main-heading-text">
      {{ `${$t('quizVlm.question.header')} *` }}
    </div>
    <Draggable
      :list="quiz.questions"
      :scroll-sensitivity="150"
      :fallback-tolerance="1"
      :force-fallback="true"
      @end="updateRichTextEditors"
      handle=".questions-drag-drop-arrows"
    >
      <div
        v-for="(question, index) in quiz.questions"
        :key="`question-${question.id ? question.id : question.fakeId}`"
        class="questions"
      >
        <div class="four-columns">
          <div class="four-columns__drag-drop">
            <div>
              {{ `${index + 1}.` }}
            </div>
            <div class="questions-drag-drop-arrows">
              <i class="fa fa-arrow-up" />
              <i class="fa fa-arrow-down" />
            </div>
          </div>
          <div class="four-columns__inputs">
            <div class="title-text">
              {{ `${$t('quizVlm.question.title')} *` }}
            </div>
            <RichTextEditor
              v-model="question.title"
              @blur="$v.quiz.questions.$each[index].title.$touch()"
              :key="`question-${question.id ? question.id : question.fakeId}-title-${question.updateKey ? question.updateKey : ''}`"
              :error="$v.quiz.questions.$each[index].title.$error"
              :other-options="richTextEditorConfig"
              :id="`question_title-${index}`"
              :readonly="disabled"
            />
            <div class="four-columns__inputs__article">
              <div>
                <div class="title-text">
                  {{ $t('quizVlm.question.article') }}
                </div>
                <button
                  :class="{ 'add-button add-button--select-article': true, 'add-button--disabled': disabled }"
                  :title="$t('quizVlm.articleModal.selectButton')"
                  :disabled="disabled"
                  @click="openArticleSelectModal(question)"
                >
                  {{ $t('quizVlm.articleModal.selectButton') }}
                </button>
              </div>
              <div v-if="question.articleTitle" class="article-title">
                <a :href="`/article/${question.articleId}`" target="_blank">
                  {{ question.articleTitle }}
                </a>
              </div>
            </div>
          </div>
          <div class="four-columns__image">
            <div class="title-text">
              {{ $t('quizVlm.question.image') }}
            </div>
            <ModuleFormPhotoBox
              :key="`question-photobox-${index}`"
              :image="question.imageInfo"
              :disabled="disabled"
              :media-usage-type="mediaUsageType"
              :site="quiz.site"
              @set-media="(medias) => setImage(question, medias)"
              @remove-media="removeImage(question)"
              box-height="220px"
              hide-separator
            />
          </div>
          <div class="four-columns__delete-button align-middle-center">
            <ModuleFormButton
              v-if="!disabled && quiz.questions.length >= 2"
              icon="delete"
              @click="removeQuestion(index)"
            />
          </div>
        </div>
        <QuizFormAnswersVlm
          ref="answers"
          :question="question"
          :quiz-type="quiz.type"
          :disabled="disabled"
        />
      </div>
    </Draggable>
    <button
      v-if="!disabled"
      class="add-button"
      :title="$t('quizVlm.question.add')"
      @click="addQuestion"
    >
      <PlusIcon class="add-button__icon" />
      {{ $t('quizVlm.question.add') }}
    </button>
    <ArticleSelectModal
      v-if="articleSelectModal"
      @select-article="selectArticle"
      @close="closeArticleSelectModal"
    />
  </section>
</template>

<script>
import Vue from 'vue'
import { uuid } from 'vue-uuid'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import QuizQuestionModel from '@/model/QuizQuestionModelVlm'
import QuizAnswerModel from '@/model/QuizAnswerModelVlm'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import { MEDIA_USAGE_TYPE_QUIZ_QUESTION } from '@/model/ValueObject/MediaUsageTypes'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import RichTextEditor from '@/components/form/RichTextEditor'
import Draggable from 'vuedraggable'
import QuizFormAnswersVlm from '@/components/quiz/vlm/QuizFormAnswersVlm'
import ArticleSelectModal from '@/components/quiz/vlm/ArticleSelectModal'
import { required } from 'vuelidate/lib/validators'
import { QUIZ_TYPE_CORRECT_ANSWER } from '@/components/mixins/valueObject/QuizTypeMixin'

export default {
  name: 'QuizFormQuestionsVlm',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ArticleSelectModal,
    QuizFormAnswersVlm,
    ModuleFormButton,
    ModuleFormPhotoBox,
    PlusIcon,
    RichTextEditor,
    Draggable
  },
  data () {
    return {
      articleSelectModal: false,
      selectedQuestion: null,
      mediaUsageType: MEDIA_USAGE_TYPE_QUIZ_QUESTION,
      richTextEditorConfig: {
        ...TinyMceConfig.getConfig(),
        height: 150,
        autoresize_min_height: 150
      }
    }
  },
  validations: {
    quiz: {
      questions: {
        $each: {
          title: {
            required
          }
        }
      }
    }
  },
  methods: {
    updateRichTextEditors (dragEnd) {
      const start = dragEnd.oldIndex < dragEnd.newIndex ? dragEnd.oldIndex : dragEnd.newIndex
      const end = dragEnd.oldIndex < dragEnd.newIndex ? dragEnd.newIndex : dragEnd.oldIndex
      for (let i = start; i <= end; i++) {
        Vue.set(this.quiz.questions[i], 'updateKey', (this.quiz.questions[i].updateKey ?? 0) + 1)
      }
    },
    addQuestion () {
      const newQuestion = this._.cloneDeep(QuizQuestionModel)
      newQuestion.answers = [this._.cloneDeep(QuizAnswerModel), this._.cloneDeep(QuizAnswerModel)]
      if (this.quiz.type === QUIZ_TYPE_CORRECT_ANSWER) {
        newQuestion.answers[0].points = 1 // true
      }
      newQuestion.fakeId = uuid.v4()
      this.quiz.questions.push(newQuestion)
    },
    removeQuestion (index) {
      this.quiz.questions.splice(index, 1)
    },
    setImage (question, medias) {
      question.imageInfo = medias[0]
      question.image = medias[0].id
    },
    removeImage (question) {
      question.imageInfo = null
      question.image = null
    },
    selectArticle (article) {
      this.selectedQuestion.articleId = article.id
      this.selectedQuestion.articleTitle = article.field.title
    },
    openArticleSelectModal (question) {
      this.selectedQuestion = question
      this.articleSelectModal = true
    },
    closeArticleSelectModal () {
      this.articleSelectModal = false
    }
  },
  created () {
    if (!this.quiz.id) {
      this.addQuestion()
    }
  }
}
</script>

<style scoped lang="scss">
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.align-middle-center {
  margin: auto;
  text-align: center;
}
.four-columns {
  display: flex;
  gap: 1%;
  &__drag-drop {
    flex: 0 0 4%;
    .questions-drag-drop-arrows {
      text-align: center;
      margin-top: rem(100px);
      cursor: pointer;
      i {
        display: block;
      }
    }
  }
  &__inputs {
    flex: 0 0 65%;
    margin-top: 1rem;
    &__article {
      display: flex;
      gap: 2%;
      align-items: flex-end;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  &__image {
    flex: 0 0 23%;
    margin-top: 1rem;
  }
  &__delete-button {
    flex: 0 0 4%;
  }
}
.questions {
  background-color: #efefef;
  margin-bottom: rem(10px);
  padding: 0.5rem;
}
.article-title {
  margin-bottom: 0.5rem;
}
.add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  margin-top: 1rem;
  height: rem(30px);
  cursor: pointer;
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
  &--select-article {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    width: rem(120px);
  }
}
</style>
